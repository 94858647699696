import React from 'react'
import { graphql } from 'gatsby'

import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import PostItem from "../components/newsletterItem"
import { Link } from "gatsby"
import styled from 'styled-components'

import EmailListForm from "../components/simpleEmailForm"


import Signup from "../components/signup";

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`
const Subscribe = (props) => {
  const { data: { allNotion } } = props
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#ebf6ff">
      <SEO title="Subscribe | Conrad" desc="Every Sunday, I share an email with community updates, honest sharing, content I've learned from, and a meaningful prompt to get your week going." />
      <PBox style= {pageAnimation} py={[8, 9, 10, 10]} px={[6, 6, 8, 6, 8, 13]}>
      {/*<EmailListForm></EmailListForm>*/}
      <div style={{ textAlign: 'center' }}>
      <Signup cta="subscribe" />
      </div>
        <h2 style={{ textAlign: 'center' }}><a href="https://join.co-x3.com/library?utm_source=conradlin.com" target="_blank">Browse Latest Issues 📰</a></h2>
        {/*
            allPosts.nodes.map(node => <PostItem data={node} />)
        */}
        </PBox>
    </Layout>
  )
}

export default Subscribe
export const query = graphql`
  query {
    allNotion(
        filter: {properties: {status: {value: {name: {eq: "published"}}}}}
        limit: 2
        sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
      ) {
        edges {
          node {
            localImage {
              childImageSharp {
                fluid(quality: 95, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            childMdx {
              frontmatter {
                title
                tags {
                  name
                }
                content_type {
                  name
                }
                desc
                status {
                  name
                }
                url
                read_time
                publish_date {
                  start(fromNow: false, formatString: "YYYY-MMM-DD")
                }
              }
              timeToRead
            }
          }
        }
    }
  }
`
